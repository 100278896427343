<template>
  <div class="page">
    <!-- 头部 -->
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
    >
      <el-menu-item index="1">驼峰翻译</el-menu-item>
    </el-menu>

    <el-row style="margin-top: 10px">
      <el-col :offset="4" :span="4">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option> </el-select
      ></el-col>
      <el-col :span="4">
        <el-button @click="translate" type="primary">翻译</el-button>
        <el-button @click="copy(result)">复制翻译结果</el-button>
      </el-col>
      <el-col style="margin-left: 10px" :span="8">
        <el-input placeholder="驼峰结果" v-model="humpResults" :disabled="true">
          <el-button
            @click="copy(humpResults)"
            slot="prepend"
            icon="el-icon-check"
          ></el-button> </el-input
      ></el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-col :offset="4" :span="8">
        <el-input
          style="font-size: 20px"
          color="#000000;"
          @input="translate"
          type="textarea"
          :rows="6"
          placeholder="请输入要翻译的内容"
          v-model="text"
        >
        </el-input
      ></el-col>
      <el-col style="margin-left: 10px" :span="8">
        <el-input
          style="font-size: 20px"
          readonly
          disabled
          type="textarea"
          :rows="6"
          v-model="result"
        >
        </el-input
      ></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      humpResults: "",
      activeIndex: "1",
      text: "",
      result: "",
      value: "中英自动检测",
      options: [
        { value: "en", label: "英语" },
        { value: "zh-Hans", label: "中文" }
      ]
    };
  },
  methods: {
    translate() {
      if (!this.text) {
        this.result = "";
        this.humpResults = "";
        return;
      }
      // 向给定ID的用户发起请求
      this.$axios
        .get("http://119.91.223.83:8001/Translationinterface?text=" + this.text)
        .then(response => {
          if (!this.text) {
            this.result = "";
            this.humpResults = "";
            return;
          }
          this.result = response.data.result;
          this.humpResults = response.data.humpResults;
        })
        .catch(function (error) {
          // 处理错误情况
          console.log(error);
        })
        .then(function () {
          // 总是会执行
        });
    },
    copy(outcome) {
      let url = outcome;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success"
      });
      oInput.remove();
    }
  }
};
</script>

<style scoped>
.page {
  margin-top: 10px;
}
</style>
